<template>
  <div>
    <b-card>
      <filter-card :get-data="getDataList" />
    </b-card>
    <b-card no-body>
      <div
        v-if="loading"
        class="p-2 text-center"
      >
        <pre-loading />
      </div>
      <template v-else-if="dataList.length > 0">
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(mamulkod)="data">
            {{ data.item.mamulkod }}
            <div class="text-primary font-small-2">
              {{ data.item.konstruksiyon }}
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="'/app/products/add/' + data.item.mamulkod "
            >
              <FeatherIcon icon="PlusCircleIcon" />
              Ekle
            </b-button>
          </template>
        </b-table>
      </template>
      <div
        v-else
        class="p-2"
      >
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <FeatherIcon icon="InfoIcon" />
            Bekleyen ürün bulunmamaktadır.
          </div>
        </b-alert>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTable, BButton, BAlert,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'
import FilterCard from '@/views/Admin/Products/Pending/FilterCard.vue'

export default {
  name: 'Index',
  components: {
    PreLoading,
    BCard,
    BTable,
    BButton,
    BAlert,
    FilterCard,
  },
  data() {
    return {
      loading: true,
      pendingLoading: true,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'mamulkod',
          label: 'MAMÜL KODU',
        },
        {
          key: 'kalite',
          label: 'KALİTE ADI',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'sen',
          label: 'EN',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'sgr',
          label: 'GRAMAJ',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-150 text-nowrap text-right',
          tdClass: 'width-150 text-nowrap text-right',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['apiProducts/dataList']
    },
    dataCounts() {
      return this.$store.getters['apiProducts/dataCounts']
    },
    filterParams() {
      return this.$store.getters['apiProducts/filterParams']
    },
  },
  created() {
    this.getDataList()
    this.getFilterData()
  },
  methods: {
    getFilterData() {
      this.$store.dispatch('apiProducts/getFilterData')
    },
    getDataList() {
      const where = {}
      const like = {}
      if (this.filterParams.pcode) {
        like['CRM_MAM_KOD.MAMULKOD'] = this.filterParams.pcode
      }
      if (this.filterParams.kalite) {
        where['CRM_MAM_KOD.KALITE'] = this.filterParams.kalite
      }
      if (this.filterParams.sen) {
        where['CRM_MAM_KOD.SEN'] = this.filterParams.sen
      }
      if (this.filterParams.sgr) {
        where['CRM_MAM_KOD.SGR'] = this.filterParams.sgr
      }
      this.$store.dispatch('apiProducts/getDataList', {
        where,
        like,
      })
        .then(response => {
          if (response) {
            this.loading = false
          }
        })
    },
  },
}
</script>
